
export default {
  name: "Loading",
  data() {
    return {
      overlay: true,
      opacity: "0.6"
    }
  }
}
