import Vue from "vue";
import loadingVue from './loading.vue';

const LoadingConstructor = Vue.extend(loadingVue);
const defaults = {
  overlay: true,
  opacity:"0.6"
};
let fullscreenLoading;//用来保存弹窗实例
LoadingConstructor.prototype.close = function () {
  // 清除弹窗实例
  if (this.fullscreen) {
      fullscreenLoading = undefined;
  }
  afterLeave(this, _ => {
      // 结束后删除节点
      if (this.$el && this.$el.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
      }
      this.$destroy();
  }, 300);
  this.visible = false;
};
// 关闭弹窗的方法
function afterLeave(instance, callback, speed = 300, once = false) {
  let called = false;
  const afterLeaveCallback = function () {
      if (called) return;
      called = true;
      if (callback) {
        callback.apply(null, arguments);
      }
  };
  setTimeout(() => {
      afterLeaveCallback();
  }, speed + 100);
};
let service = (options = {}) => {
  // 合并options,源码中用的是 Object.assign 的 profill
  options = Object.assign({}, defaults, options);
  // 如果没有 target ，那么弹窗将会直接挂载在body上
  console.log('options',options);
  
  if (typeof options.target === 'string') {
      options.target = document.querySelector(options.target);
  }
  options.target = options.target || document.body;
  if (options.target !== document.body) {
      options.fullscreen = false;
  } else {
      options.body = true;
  }
  // 覆盖整个body的loading只能有一个
  if (options.fullscreen && fullscreenLoading) {
      return fullscreenLoading;
  }
  let instance = new LoadingConstructor({
      el: document.createElement('div'),
      data: options
  });
  // 如果没有 target ，那么弹窗将会直接挂载在body上
  let parent = options.body ? document.body : options.target;
  parent.appendChild(instance.$el);
  Vue.nextTick(() => {
      instance.visible = true;
  });
  // 将实例赋值给 fullscreenLoading
  if (options.fullscreen) {
      fullscreenLoading = instance;
  }
  return instance;
}

const Load = {
  install: (Vue, otps = {}) => {
    Vue.prototype.$loading = service
  },
  service
}

export default Load