/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message_bar: {
      show_message: false,
      message: '',
      msg_type: 'success',
    },
    loading: false,
  },
  getters: {
    message_bar: (state)=> {
      return state.message_bar;
    },
  },
 mutations: {
    SET_MESSAGE(state, msg_bar) {
      state.message_bar=msg_bar
    },
    CLEAR_MESSAGE(state) {
      state.message_bar = {
        show_message:false,
        message:'',
        msg_type:'success',
      }
    },
    setLoading(state, flage){
      state.loading = flage
    }
  },
  actions: {
  },
  modules: {
    auth
  }
})
