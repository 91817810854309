/*
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
*/
import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import MathJax from './plugins/MathJax'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/global.css";
import "./store/subscriber";
import '@mdi/font/css/materialdesignicons.css'
import Load from '@/components/load'

Vue.prototype.MathJax = MathJax;

//axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = window.location.origin + "/api";

console.log(window.location.origin + "/api");
Vue.config.productionTip = false;
Vue.directive('dateFormat', (el,datetime)=>{
    el.innerText = datetime.value.slice(0, 19).replace("T", " ")
  }
)
Vue.use(Load);
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
