<!--
    Author: Huajie Liu 
    Email: jack818liu@gmail.com
-->
<template>
  <default-layout>
    <router-view></router-view>
  </default-layout>
</template>

<script Lang="ts">
import DefaultLayout from "./components/DefaultLayout.vue";
import store from "@/store";
export default {
  components: { DefaultLayout },
  data(){
    return {
      flage:false,
      workload:null
    }
  },
  computed: {
    newData() {
      return store.state.loading
    }
  },
  watch: {
    newData(newVal) {
      this.flage = newVal
      if (newVal) {
        this.workload = this.$loading()
      }else{
        if (this.workload) {
          this.workload.close();
        }
      }
    }
  },
  mounted: () => {
    /* console.log("app mounted"); */
  },
};
</script>
